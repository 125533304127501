import config from './config';
import {
	postRequest
} from '../utils/http-request';

/**
 * 获取文件收集列表
 * @param {object} dataForm - 请求参数
 * 
 * @returns {Promise<object>} - 响应数据
 */
export function getCollectFolderList(dataForm, callback) {
	postRequest(config.url.collect.collectFolderList, dataForm, callback);
}

/**
 * 获取收集的文件资料列表
 * @param {object} dataForm - 请求参数
 * @returns {Promise<object>} - 响应数据
 */
export function getCollectFileList(dataForm, callback) {
	postRequest(config.url.collect.collectFileList, dataForm, callback);
}

/**
 * 新建文件收集资料
 * @param {object} dataForm - 请求参数
 * @returns {Promise<object>} - 响应数据
 */
export function createCollect(dataForm, callback) {
	postRequest(config.url.collect.createCollect, dataForm, callback);
}