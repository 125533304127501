import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



import {postRequest, getRequest, putRequest, deleteRequest} from "./utils/http-request";
// import {getRequest} from "./utils/http-request";
// import {putRequest} from "./utils/http-request";
// import {deleteRequest} from "./utils/http-request";

Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.postRequest = postRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );

import VueDeviceDetector from 'vue-device-detector';
Vue.use(VueDeviceDetector);

import longpressDirective from './directives/longpress';
Vue.config.productionTip = false;
Vue.use(longpressDirective);


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
