<template>
	<div class="files-main-list">
		<!-- <div class="btn-group__wrapper" >
      <el-button-group>
	  <el-button size="small" icon="el-icon-delete" @click="cleanRecycle">清空回收站</el-button>
      </el-button-group>
   </div> -->

		<div style="text-align: left;">
			<el-button size="small" icon="el-icon-delete" @click="cleanRecycle">清空回收站</el-button>
		</div>

		<div class="no-main-file-list">
			<div class="nd-table is-show-header">
				<template>
					<div class="box">
						<div class="tableBox">
							<div class="gridRecycle">
								<div class="grid-item" :class="{checked: item.checked}"
									v-for="(item,index) in fileDataList">
									<img :src="getImgSrc(item)">
									<span :title="item.fileName">{{ item.fileName }}</span>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Message
	} from "element-ui";
	import {
		getRecycleList
	} from "../../../api/recycle";
	import config from "../../../api/config";
	import {
		postRequest
	} from "../../../utils/http-request";

	export default {
		name: "recyclePage",
		data() {
			return {
				fileDataList: []
			}
		},
		mounted() {
			this.initGetRecycleList();
		},
		methods: {
			initGetRecycleList() {
				let vm = this;
				getRecycleList({}, function(res) {
					if (res.code === 200 && res.success === true) {
						vm.fileDataList = res.data.content;
					} else {
						Message.error(res.message);
					}
				})
			},

			//清空回收站
			cleanRecycle() {
				this.$confirm('此操作将永久清空回收站，是否继续？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					postRequest(config.url.recycle.cleanRecycles,{}, function(res) {
						if (res.code === 200 && res.success === true) {
							Message.success(res.message);
							this.fileDataList = [];
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消清空'
					});
				});
			},

			// 文件图标获取
			getImgSrc(item) {
				let isDir = item.isDir,
					suffix = item.suffix,
					category = item.category,
					fileId = item.fileId;
				let icon = 'other';
				if (category === 3 || category === 1) {
					icon = config.url.file.getImgUrl + fileId;
					return icon;
				} else {
					if (isDir == 1) {
						icon = "folder";
					} else {
						switch (category) {
							// case 1:
							//   icon = "video";
							//   break;
							case 2:
								icon = "music";
								break;
							case 4: //文本
								icon = suffix;
								if (icon == "doc" || icon == "docx") {
									icon = "doc";
								} else if (icon == "xls" || icon == "xlsx" || icon == "csv") {
									icon = "xls";
								}
								break;
							case 5: //应用
							case 6: //压缩包
								icon = suffix;
								break;
							case 7:
								icon = "torrent";
								break;
							case 8:
								icon = "code";
								break;
							case 9:
							default:
								icon = "other";
								break;
						}
					}
					return `/cloud/image/file_type/${icon}.png`;
				}

			},
		}
	}
</script>

<style>
	/* div {
		border: 1px solid red;
	} */

	.files-main-list {
		height: 100%;
		padding: 10px;
	}

	.nd-main-list__table {
		margin-left: -18px;
	}

	.nd-main-list__table {
		height: calc(100% - 80px);
		width: calc(100% - 272px);
		display: inline-block;
	}

	.nd-main-list__table {
		min-height: 356px;
	}

	.btn-group__wrapper {
		display: inline-block;
		vertical-align: middle;
	}

	.nd-table {
		height: 100%;
	}

	.box {
		width: 100%;
		height: 100%;
	}

	.tableBox {
		width: 100%;
		height: 100%;
		background: white;
	}

	.tableBox .gridRecycle {
		margin-top: 40px;
		width: 100%;
		height: calc(100vh - 150px);
		overflow-y: scroll;
	}

	/* 格子布局样式 --- 开始*/
	.grid-item {
		float: left;
		width: 120px;
		height: 127px;
		margin: 4px 0 0 6px;
		border: 1px solid #fff;
		padding: 10px;
	}

	.grid-item.checked {
		border: 1px solid #90d8ff;
		border-radius: 5px;
		background-color: #f1f5fa;
	}
</style>