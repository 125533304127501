// 导入配置信息和请求方法
import config from './config'
import {
	postRequest,
	getRequest
} from '../utils/http-request'

// 登录请求
export function login(dataForm, callback) {
	console.log(config.companyCode)
	console.log(dataForm)
	dataForm.companyCode = config.companyCode;
	dataForm.appId = config.appId;
	console.log(dataForm)
	postRequest(config.url.auth.login, dataForm, callback)
}

// 退出登录请求
export function logout(callback) {
	getRequest(config.url.auth.logout, callback)
}

// 注册请求
export function register(dataForm, callback) {
	dataForm.companyCode = config.companyCode;
	dataForm.appId = config.appId;
	postRequest(config.url.auth.register, dataForm, callback);

}

// 获取用户信息请求
export function getUserInfo(callback) {
	getRequest(config.url.auth.getUserByToken + window.sessionStorage.getItem('token'), callback);
}

// 修改用户信息
export function updateUser(dataForm, callback) {
	dataForm.companyCode = config.companyCode;
	postRequest(config.url.sysUser.updateUser, dataForm, callback);

}

// 获取内存使用情况请求
export function getMemory(callback) {
	getRequest(config.url.file.getMemory, callback);
}

// 修改密码请求
export function changePwd(dataForm, callback) {
	postRequest(config.url.auth.changePassWord, dataForm, callback);

}