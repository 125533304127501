import config from './config'
import {
	postRequest,
	getRequest,
	putRequest,
	deleteRequest
} from "../utils/http-request";

//获取回收站文件
export function getRecycleList(dataForm, callback) {
	postRequest(config.url.recycle.getRecycleList, dataForm, callback);
}