<template>
	<div id="app">
		<div class="user-profile">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>个人中心</span>
				</div>
				<div class="user-info">
					<div class="avatar">
						<el-upload class="avatar-uploader" :action="uploadPath" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :data="uploadData">
							<img v-if="user.photo" :src="user.photo" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
					<el-form :model="user" label-width="80px">
						<el-form-item label="用户名">
							<el-input v-model="user.name"></el-input>
						</el-form-item>
						<el-form-item label="电话">
							<el-input v-model="user.phone"></el-input>
						</el-form-item>
						<el-form-item label="性别" style="text-align: left; width: 150px;">
							<el-select v-model="user.sex" placeholder="请选择性别">
								<el-option label="男" value="男"></el-option>
								<el-option label="女" value="女"></el-option>
							</el-select>														
						</el-form-item>
						<el-form-item label="注册日期">
							<div style="text-align: left;">{{ user.createDate }}</div>
						</el-form-item>
						<el-form-item label="存储空间">
							<div style="text-align: left;">{{ usedMemoryG }}G/2048G
							<el-progress width="100%" :percentage="percentage" :show-text="false" :color="color"></el-progress>
							</div>							
						</el-form-item>
					</el-form>
				</div>
				<div class="user-actions">
					<el-button type="primary" @click="saveProfile">保存</el-button>
					<el-button @click="resetProfile">返回</el-button>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	import config from '/src/api/config.js';
	import {
		updateUser,
		getMemory,
	} from '/src/api/auth.js';
	export default {
		data() {
			return {
				user: {
					photo: '',
					name: '',
					phone: '',
					sex: '',
					createDate: ''
				},
				uploadPath: '',
				uploadData: {
					parentPath: '/基础数据/个人头像/',
					permission: 'public',
					uploadType: 0
				},
				usedMemoryG:0,
				percentage:0,

			};
		},
		created() {
			this.loadUserData();
			this.uploadPath = config.url.file.singleUpload;
		},
		methods: {
			loadUserData() {
				const cachedUserInfo = sessionStorage.getItem('userInfo');
				if (cachedUserInfo) {
					const userInfo = JSON.parse(cachedUserInfo);
					this.user = {
						id: userInfo.id,
						photo: userInfo.photo,
						name: userInfo.name,
						sex: userInfo.sex || '',
						phone: userInfo.phone,
						createDate: userInfo.createTime.substring(0, 10) // 提取创建日期的前10个字符
					};
					//如果加载成功，查询使用情况
					this.initGetMemory();
				}
			},
			initGetMemory() {
				getMemory((res)=>{
					this.usedMemoryG = (res.data / 1024 / 1024 / 1024).toFixed(2);
					this.percentage = Number((this.usedMemoryG / 2048 * 100).toFixed(2));
				})
			},
			handleAvatarSuccess(res, file) {
				if (res.code == 200) {
					Message.success(res.message);
					this.user.photo = config.url.file.getImgUrl + res.data;
				} else {
					Message.error(res.message);
				}
			},
			beforeAvatarUpload(file) {
				const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isImage) {
					this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isImage && isLt2M;
			},
			saveProfile() {
				let that = this;
				updateUser(that.user, function(res) {
					if (res.code == 200) {
						Message.success(res.message);
						//读取缓存
						const cachedUserInfo = sessionStorage.getItem('userInfo');
						const userInfo = JSON.parse(cachedUserInfo);
						//赋值
						userInfo.photo = that.user.photo;
						userInfo.sex = that.user.sex;
						userInfo.name = that.user.name;
						userInfo.phone = that.user.phone;
						//写入缓存
						sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
					} else {
						Message.error(res.message);
					}
				});
			},
			resetProfile() {
				this.$router.replace("/home");
			}
		}
	};
</script>

<style scoped>
	.user-profile {
		width: 100%;
		max-width: 450px;
		margin: 20px auto;
	}

	.avatar {
		text-align: center;
		margin-bottom: 20px;
	}

	.avatar-uploader {
		display: inline-block;
		width: 150px;
		height: 150px;
		border: 1px dashed #d9d9d9;
		border-radius: 50%;
		overflow: hidden;
		cursor: pointer;
		position: relative;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.avatar {
		width: 100%;
		height: 100%;
		display: block;
	}

	.user-info {
		margin-bottom: 20px;
	}

	.user-actions {
		text-align: right;
	}
</style>