export default {
  install(Vue) {
    Vue.directive('longpress', {
      bind: function (el, binding) {
        if (typeof binding.value !== 'function') {
          console.warn(`[Vue-longpress] provided expression '${binding.expression}' is not a function`);
          return;
        }

        let pressTimer = null;

        const start = (e) => {
			console.log('点击');
          if (e.type === 'click' && e.button !== 0) {
            return;
          }
          if (pressTimer === null) {
            pressTimer = setTimeout(() => {
              handler();
            }, 1000); // 长按1秒触发
          }
        };

        const cancel = () => {
			console.log('取消');
          if (pressTimer !== null) {
            clearTimeout(pressTimer);
            pressTimer = null;
          }
        };

        const handler = (e) => {
          binding.value(e);
        };

        el.addEventListener('mousedown', start);
        el.addEventListener('touchstart', start);
        el.addEventListener('click', cancel);
        el.addEventListener('mouseout', cancel);
        el.addEventListener('touchend', cancel);
        el.addEventListener('touchcancel', cancel);
      },
      unbind: function (el) {
        el.removeEventListener('mousedown', start);
        el.removeEventListener('touchstart', start);
        el.removeEventListener('click', cancel);
        el.removeEventListener('mouseout', cancel);
        el.removeEventListener('touchend', cancel);
        el.removeEventListener('touchcancel', cancel);
      }
    });
  }
};
