import config from './config'
import {postRequest, getRequest, putRequest, deleteRequest} from "../utils/http-request";

//获取文件分享记录列表
export function shareFiles(dataForm, callback) {
    postRequest(config.url.share.shareFiles, dataForm,callback);
}

//获取文件分享记录列表
export function getShareFilesList(dataForm, callback) {
    postRequest(config.url.share.getShareFileList, dataForm,callback);
}