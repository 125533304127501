import config from './config'
import {
	postRequest,
	getRequest,
	putRequest,
	deleteRequest
} from "../utils/http-request";
import MessageBox from '@/components/message-box'
import result from "element-ui/packages/result";
import {
	Message
} from "element-ui";
import router from "../router";
import axios from "axios";

//获取类型列表
export function getTypeList(dataForm, callback) {
	postRequest(config.url.notes.typeList, dataForm, callback);
}

//获取封面列表
export function getCoverList(dataForm, callback) {
	postRequest(config.url.notes.coverList, dataForm, callback);
}

//添加封面
export function addCover(dataForm, callback) {
	postRequest(config.url.notes.addCover, dataForm, callback);
}

//更新封面
export function updateCover(dataForm, callback) {
	postRequest(config.url.notes.updateCover, dataForm, callback);
}

//删除封面
export function deleteCover(id, callback) {
	getRequest(config.url.notes.deleteCover, callback);
}


//获取文章列表
export function getNotesList(dataForm, callback) {
	postRequest(config.url.notes.list, dataForm, callback);
}

//添加文章
export function addNotes(dataForm, callback) {
	postRequest(config.url.notes.add, dataForm, callback);
}

//更新文章
export function updateNotes(dataForm, callback) {
	postRequest(config.url.notes.update, dataForm, callback);
}

//删除文章
export function deleteNotes(id, callback) {
	getRequest(config.url.notes.delete, callback);
}