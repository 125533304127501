<template>
	<div id="page-container">
		<main id="main-container">
			<div class="board-background"></div>
			<div class="no-gutters board-auth-box">
				<div style="max-width: 450px; width: 90%; margin: auto;">
					<el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer" label-width="25%">
						<h3 class="loginTitle">用户登录</h3>
						<div style="color: #8f8f8f;">{{selectedSlogan}}</div>
						<br />
						<el-form-item prop="username" label="用 户">
							<el-input style="border-radius: 10px;" type="text" autocomplete="true" v-model="loginForm.username"
								placeholder="请输入用户名"></el-input>
						</el-form-item>
						<el-form-item prop="password" label="密 码">
							<el-input style="border-radius: 10px;" type="password" autocomplete="true" v-model="loginForm.password"
								placeholder="请输入密码"></el-input>
						</el-form-item>
						<el-button style="width: 40%;border-radius: 20px;margin: 10px 0;" type="primary" @click="submitLogin">登录</el-button>
						<!-- <el-button style="width: 40%;border-radius: 20px;" @click="toGoRegister">注册</el-button> -->
					</el-form>
				</div>
			</div>
		</main>
	</div>
</template>


<script>
	import router from "../../router";
	import {
		login
	} from "../../api/auth";
	import {
		Message
	} from "element-ui";

	export default {
		name: "Login",
		mounted() {
			window.addEventListener('keydown', this.handleKeyDown);


		},
		beforeDestroy() {
			window.removeEventListener('keydown', this.handleKeyDown);
		},
		data() {
			return {
				loginForm: {
					username: '',
					password: ''
				},
				slogans: [
					'智慧云存，触手可及',
					'智慧连接，数据无界',
					'快如闪电，稳如云霄',
					'云闪存，为您的数据保驾护航！',
					'你的记忆，AI守护',
					'一刻不漏，智能备份',
					'数据智管，智慧未来',
					'跨界互联，智能云端',
					'点滴智聚，创见未来',
					'智慧云盘，连接你我'
				],
				selectedSlogan: '', // 初始化时没有选中的标语
				rules: {
					companyCode: [{
						required: true,
						message: '请输入公司代码',
						trigger: 'blur'
					}],
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					appId: [{
						required: true,
						message: '请输入应用id',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted() {
			this.selectRandomSlogan();
		},

		methods: {
			selectRandomSlogan() {
				// 从slogans数组中随机选择一个标语
				const index = Math.floor(Math.random() * this.slogans.length);
				this.selectedSlogan = this.slogans[index];
			},
			handleKeyDown(event) {
				if (event.key === 'Enter') {
					event.preventDefault();
					this.submitLogin();
				}
			},
			submitLogin() {
				this.$refs.loginForm.validate((valid) => {
					if (valid) {
						login(this.loginForm, function(res) {
							if (res.code === 200) {
								console.log(res);
								Message.success('登陆成功！')
								//存储用户token
								const tokenStr = res.data.token;
								sessionStorage.setItem('userInfo', JSON.stringify(res.data));
								sessionStorage.setItem('token', tokenStr);
								sessionStorage.setItem('path', '/');
								//跳转到首页
								router.replace('/Home');
								location.reload(); //刷新一下，修改第一次上传bug
							} else {
								Message.error('登陆失败，请联系管理员！');
							}
						})
					} else {						
						return false;
					}
				});
			},
			toGoRegister() {
				router.replace('/Register');
			}
		}
	}
</script>

<style>
	#page-container {
		min-height: 100%;
	}

	#main-container {
		background-color: #f0f3f8 !important;
	}

	.board-background {
		background-image: url("/cloud/image/backgroundImg.png");
		background-repeat: no-repeat;
		background-position: 41.5% 50%;
		background-color: #fff;
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
	}

	.no-gutters {
		margin-right: 0;
		margin-left: 0;
	}

	.board-auth-box {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		overflow-y: auto;
	}

	.loginContainer {
		border-radius: 15px;
		padding: 15px 35px 15px;
		background: #fff;
		border: 1px solid #eaeaea;
		box-shadow: 0 0 25px #cac6c6;
	}

	.loginTitle {
		margin: 0px auto 25px auto;
		text-align: center;
	}

	/*div {*/
	/*  border: 1px solid red;*/
	/*}*/
</style>