<template>

	<div>
		<div class="notes_page">

			<!-- 文件夹列 -->
			<div class="cover_div">
				<el-button type="primary" plain @click="addFolder">新增文件夹</el-button>
				<div :class="currentCover.id==item.id?'cover_item active':'cover_item'"
					v-for="(item,index) in coverList" @click="handleCoverSelect(item)">
					<div>
						<img width="15px" height="15px" style="margin: 10px;"
							src="../../../../public/image/file_type/folder.png" />
						<div>{{item.name}}</div>
						<!-- <div>{{item.size}}</div> -->
					</div>
					<!-- <i class="el-icon-more" @click.stop="handleCoverSelectMore($event,item)"></i> -->
					<el-popover placement="right" width="100" trigger="click">
						<div class="right-popover">
							<el-button class="btn" type="primary" @click="handleCoverEditById(item)">修改</el-button>
							<el-popconfirm confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info"
								icon-color="red" hide-icon :title="'确定要删除文件夹 '+item.name+' 吗?'"
								@confirm="handleCoverDelById(item)">
								<el-button class="btn" type="danger" slot="reference">删除</el-button>
							</el-popconfirm>
						</div>
						<i slot="reference" class="el-icon-more" @click.stop="handleCoverSelectMore(item)"></i>
					</el-popover>
				</div>

			</div>

			<!-- 文章名称列 -->
			<div class="notes_div">
				<el-button type="primary" plain @click="addFile">新增文件</el-button>
				<!-- <el-row>
					<el-button icon="el-icon-search" circle></el-button>
					<el-button type="primary" icon="el-icon-edit" circle></el-button>
					<el-button type="success" icon="el-icon-check" circle></el-button>
					<el-button type="info" icon="el-icon-message" circle></el-button>
					<el-button type="danger" icon="el-icon-delete" circle></el-button>
				</el-row> -->
				<div :class="currentNotes.id==item.id?'notes_item active':'notes_item'"
					v-for="(item,index) in notesList" @click="handleNotesSelect(item)">
					<div>
						<div class="title">{{item.title}}</div>
						<div class="content">
							<p>{{checkDate(item.updateTime)}}</p>
							<p>{{checkContent(item)}}</p>
						</div>
					</div>


					<el-popover placement="right" width="100" trigger="click">
						<div class="right-popover">
							<el-button class="btn" type="primary" @click="handleNotesShareById('item')">分享</el-button>
							<el-popconfirm confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info"
								icon-color="red" hide-icon :title="'确定要删除文件 '+'item.name'+' 吗?'"
								@confirm="handleNotesDelById('item')">
								<el-button class="btn" type="danger" slot="reference">删除</el-button>
							</el-popconfirm>
						</div>
						<i slot="reference" class="el-icon-more" @click.stop="handleNotesSelectMore('item')"></i>
					</el-popover>
				</div>
			</div>

			<!-- 文章内容详情列 -->
			<div class="content_div">
				<ckeditor ref="abc123" :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
			</div>
		</div>
	</div>

</template>

<style>
	.notes_page div {
		/* border: 1px solid red; */
	}

	.notes_page {
		display: flex;
	}

	/* 封面 */
	.cover_div {
		border: 1px solid gainsboro;
		height: 700px;
		padding: 10px;
		width: 200px;

	}

	.cover_div>.el-button {
		margin-bottom: 10px;
	}

	.cover_item {
		border-radius: 5px;
		border: 1px solid #fff;
		padding: 10px;
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: space-between;
	}

	.cover_item>div {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.cover_item>div>div {
		/* overflow:hidden;
		text-overflow:ellipsis; */
		word-break: break-all;
		text-align: left;
	}

	.cover_item:hover {
		background-color: aliceblue;
	}

	.cover_item.active {
		border-color: aliceblue;
		background-color: aliceblue;
	}

	.cover_item .el-icon-more {
		display: none;
	}

	.cover_item:hover .el-icon-more {
		display: block;
	}

	.right-popover {
		display: flex;
		flex-direction: column;
	}

	.right-popover .btn {
		width: 100%;
		margin: 0 0 10px 0 !important;
	}

	.right-popover .btn:last-child {
		margin-bottom: 0 !important;
	}

	/* 笔记 */

	.notes_div {
		border-radius: 5px;
		border: 1px solid gainsboro;
		width: 300px;
		height: 700px;
		padding: 10px;
		position: relative;
	}

	.notes_div>.el-row {
		margin-bottom: 10px;
	}

	.notes_item {
		border-radius: 5px;
		border: 1px solid #fff;
		padding: 10px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.notes_item:hover {
		background-color: aliceblue;
	}

	.notes_item.active {
		border-color: aliceblue;
		background-color: aliceblue;
	}

	.notes_item .el-icon-more {
		display: none;
	}

	.notes_item:hover .el-icon-more {
		display: block;
	}

	.notes_item>div {
		display: flex;
		flex-direction: column;
		flex: 1;
		text-align: left;

	}

	.notes_item>div .title {
		font-weight: 600;
		width: 240px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: keep-all;
		white-space: nowrap;
	}

	.notes_item>div .content {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 240px;
	}

	.notes_item>div .content p {
		width: 100px;
	}

	.notes_item>div .content p:last-child {
		width: 140px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: keep-all;
		white-space: nowrap;
	}

	/* 内容 */


	.content_div {
		border-radius: 5px;
		border: 1px solid gainsboro;
		/* width: 400px; */
		/* height: 700px; */
		padding: 10px;
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	.content_div .ck-editor {
		height: 100%;
	}

	.ck-powered-by {
		display: none;
	}

	.cover_item:hover {
		background-color: aliceblue;
	}

	.cover_div,
	.notes_div,
	.content_div {
		height: calc(100vh - 56px);
	}

	.content_div {
		padding: 0 2px 0 0;
	}

	.content_div .ck-editor {
		height: calc(100vh - 56px) !important;
	}

	.content_div .ck-content {
		height: calc(100vh - 56px - 40px) !important;
		padding-left: 20px;
	}

	.notes_div>.el-button {
		margin-bottom: 10px;
	}
</style>

<script>
	import {
		getTypeList,
		getCoverList,
		addCover,
		updateNotes,
		addNotes,
		getNotesList
	} from "../../../api/notes";

	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


	export default {
		data() {
			return {
				coverList: [],
				notesList: [],

				currentCover: {},
				currentNotes: {},


				editor: ClassicEditor,
				editorData: '',
				editorConfig: {
					// The configuration of the editor.
				},
				isSave: "",

			};
		},
		computed: {
			checkDate() {
				return (val) => {
					if (val) {
						let _val = val.split(" ")
						return _val[0]
					} else {
						const today = new Date();
						let year = today.getFullYear();
						let month = today.getMonth() + 1;
						let day = today.getDate();
						month = month < 10 ? '0' + month : month;
						day = day < 10 ? '0' + day : day;
						const formattedDate = `${year}-${month}-${day}`;
						return formattedDate
					}

				}
			},
			checkContent() {
				return (item) => {
					if (this.currentNotes.id == item.id) {
						let content = document.getElementsByClassName('ck-content')[0].textContent;
						let title = document.getElementsByClassName('ck-content')[0].childNodes[0].textContent;
						return content.substr(title.length)
					} else {
						let div = document.createElement("div");
						div.innerHTML = item.content;
						return div.textContent
					}

				}
			}
		},
		mounted() {
			this.loadCover();
		},
		watch: {
			editorData(newVal, oldVal) {
				// console.log(newVal)

				let title = document.getElementsByClassName('ck-content')[0].childNodes[0].textContent;
				// let img=document.getElementsByTagName('.ck-content img')
				console.log("title", title);
				// console.log("img",img)
				if (title) {
					this.currentNotes.title = title
				}
				this.saveNotes();
			}
		},
		methods: {
			loadCover() {
				//加载封面信息
				getCoverList({"createUser":JSON.parse(sessionStorage.getItem('userInfo')).id}, (res) => {
					this.coverList = res.data.content;
					//加载成功后，显示第一个封面下的文章
					this.handleCoverSelect(this.coverList[0]);
				});
			},


			loadNotes(coverId) {
				//加载文章信息
				getNotesList({
					coverId
				}, (res) => {
					console.log(res);
					this.notesList = res.data.content;
					//加载成功后，显示第一篇文章
					this.handleNotesSelect(this.notesList[0])
					// this.editorData = this.notesList[0] ? this.notesList[0].content : '';

				});
			},

			saveNotes() {

				if (this.isSave) {
					clearTimeout(this.isSave)
				}
				let that = this;

				this.isSave = setTimeout(function() {
					let content = document.getElementsByClassName('ck-content')[0].childNodes;
					let _result = [];
					for (let i in content) {
						if (i != 0) {
							_result.push(content[i].outerHTML)
						}
					}
					let _content = _result.join("");

					let _data = that.currentNotes
					_data.content = _content


					//保存文章信息
					if (_data.id > 0) {
						console.log("保存，更新", _data)
						updateNotes(_data, (res) => {
							console.log(res)
						});
					} else {
						console.log("保存，新增", _data)
						_data.coverId = that.currentCover.id;
						addNotes(_data, (res) => {
							console.log(res)
						});
					}

					// console.log("保存", _data)
				}, 3000)


			},

			handleCoverSelect(item) {
				this.currentCover = item;
				//选中封面，加载文章
				this.loadNotes(item.id);

			},
			handleCoverSelectMore(item) { //点击文件夹右边的更多
				console.log("点击文件夹右边的更多", item)
			},
			handleCoverEditById(item) {
				console.log("点击了修改文件夹", item)
			},
			handleCoverDelById(item) {
				console.log("点击了删除文件夹", item)
			},


			handleNotesSelect(item) {
				if(item){
					this.currentNotes = item;
					this.editorData = `<h1>${item.title}</h1>` + item.content;
				}else{
					this.currentNotes = {};
					this.editorData = '';
				}
				
			},
			handleNotesSelectMore() { //点击文件右边的更多
				console.log("点击文件右边的更多")
			},
			handleNotesShareById() {
				console.log("点击分享文件")
			},
			handleNotesDelById() {
				console.log("点击删除文件")
			},
			addFolder() {
				// 点击新增文件夹
				this.$prompt('请输入文件夹名字', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValidator: function(val) {
						if (val == null || val == undefined) { //判定不能为空
							return false
						} else {
							return true
						}
					},
					inputErrorMessage: '文件夹名字不能为空'
				}).then(({
					value
				}) => {
					if (value != "" && value != null && value != undefined) {
						this.$message({
							type: 'success',
							message: '文件夹名字是: ' + value
						});

						addCover({
							name: value
						}, (res) => {
							this.coverList.push({
								name: value
							})
						});

					} else {
						this.$message({
							type: 'warn',
							message: '文件夹名字不能为空'
						});
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消新建文件夹'
					});
				});

			},
			addFile() { //新增文件
				console.log(this.notesList)
				let item = {
					id: 0,
					title: "新增文件",
					content: ""
				};
				this.notesList = [item, ...this.notesList];
				this.handleNotesSelect(this.notesList[0])

			},
		},
	};
</script>