<template>
	<div class="">

		<!-- <div>
			<div class="wp-disk-header">
				<div class="wp-disk-header_left">
					<a class="wp-logo">
						<i><img src="/cloud/image/logo.png"></i>
					</a>
				</div>
				<div class="wp-disk-header-right">
					<el-dropdown class="userInfo" @command="commandHandler">
						<span class="el-dropdown-link">
							<i class="userName">手机-{{ this.username }}</i>
							<i><img v-if="this.photo !=null && this.photo != ''" :src="this.photo"><img v-else
									src="/cloud/image/avatar.png"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="personalCenter">个人中心</el-dropdown-item>
							<el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
							<el-dropdown-item command="logout">退出登陆</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div> -->

		<!-- <div class="nd-main-layout__wrapper">
			<div class="nd-aside-wp-block">
				<div class="wp-aside-nav__sub-top">
					<el-menu router v-for="(item, index) in this.$router.options.routes" :key="index"
						v-if="!item.hidden" style="border-right: none; text-align: left">
						<el-menu-item :index="children.path" v-for="(children, indexj) in item.children">
							<i :class="children.iconCls"></i>
							<span>{{ children.name }}</span>
						</el-menu-item>
					</el-menu>
				</div>
				<div class="wp-aside-nav__sub-bottom">
					<el-progress :percentage="percentage" :color="color"></el-progress>
					<span class="wp-aside-nav__quota-body-text">{{ usedMemoryG }}G/2048G</span>
				</div>
			</div>
			
		</div> -->
		<div class="">
			<router-view />
		</div>		
		
	</div>
</template>

<script>
	import {
		getMemory,
		getUserInfo,
		logout
	} from "../api/auth";
	import {
		Message
	} from "element-ui";
	import router from "../router";
	import Utils from "../assets/util";


	export default {
		name: "Home",
		data() {
			return {
				usedMemoryB: 0,
				usedMemoryG: 0,
				percentage: 0,
				color: '#409eff',
				username: '',
				photo: '',
			}
		},
		computed: {

		},
		mounted() {
			this.initGetUserInfo();
			this.initGetMemory();

			//监听更新容量
			Utils.$on('getMemory', ()=> {
				this.initGetMemory();
			})
		},

		methods: {
			isMobile() {
				// 通过 VueDeviceDetector 提供的 $device 对象判断是否为移动设备			
				return this.$device.mobile;
			},
			initGetUserInfo() {
				const cachedUserInfo = sessionStorage.getItem('userInfo');
				if (cachedUserInfo) {
					const userInfo = JSON.parse(cachedUserInfo);
					this.$set(this, 'username', userInfo.name);
					this.$set(this, 'photo', userInfo.photo);
				}
			},

			initGetMemory() {
				let vm = this;
				getMemory(function(res) {
					vm.usedMemoryB = res.data;
					vm.usedMemoryG = (vm.usedMemoryB / 1024 / 1024 / 1024).toFixed(2);
					vm.percentage = Number((vm.usedMemoryG / 2048 * 100).toFixed(2));
				})
			},

			commandHandler(command) {
				if (command == 'logout') {
					logout(function(res) {
						if (res) {
							Message.success('退出成功！')
							//清除缓存
							sessionStorage.removeItem('userInfo');
							sessionStorage.removeItem('path');
							sessionStorage.removeItem('token');
							//跳转到登陆页面
							router.replace('/');
						}
					})
				} else if (command == 'changePwd') {
					router.replace('/changePassword')
				} else if (command == 'personalCenter') {
					router.replace('/personalCenter')
				}
			}
		}
	}
</script>

<style>
	.home {
		height: 100%;
		width: 100vw;
	}


	.wp-disk-header {
		-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
		box-shadow: 0 3px 10px 0 rgb(0 0 0 / 6%);
		height: 56px;
		width: 100vw;
		line-height: 56px;
		padding-left: 22px;
		padding-right: 30px;
		position: relative;
		z-index: 2000;
	}

	.wp-disk-header_left {
		position: absolute;
	}

	.wp-logo {
		height: auto;
	}

	.wp-logo img {
		border: 0;
		vertical-align: middle;
		width: 70px;
		margin-left: 15px;
	}

	.wp-disk-header_tittle {
		position: absolute;
		font-size: 25px;
		font-family: "华文楷体";
		color: black;
		left: 60px;

	}

	.wp-disk-header-right {
		float: right;
	}

	.el-dropdown {
		height: 50px;
	}

	.userInfo {
		vertical-align: middle;
	}

	.userName {
		text-align: right;
	}

	.el-dropdown-link img {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		margin-left: 8px;
		margin-top: -15px;
	}

	.nd-main-layout__wrapper {
		height: calc(100% - 56px);
		overflow: hidden;
		width: 100%;

	}

	.nd-aside-wp-block {
		height: 100%;
		display: inline-block;
		position: relative;
		z-index: 100;
		border-right: 1px solid #f1f2f4;
		width: 180px;
		vertical-align: top;
	}

	wp-aside-nav__sub-top {
		height: 100%;
	}

	.wp-aside-nav__sub-bottom {
		position: absolute;
		bottom: 25px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
	}

	.nd-main-layout__body {
		
	
		/* width: calc(100% - 181px); */
		/* overflow: auto; */
	}
</style>