<template>
	<div>
		<h1 class="title">版本发布记录</h1>

		<div class="div-content-item" v-for="(item,index) in versionList">
			<h3>{{item.title}}</h3>
			<div>
				<p v-for="(contentItem,index) in item.content">{{contentItem}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "versionPage",
		data() {
			return {
				versionList: [{
					title: "版本规划/功能预告",
					content: ['1.新增 支持将云盘挂载到电脑中','2.新增 影视仓库功能-已发布','3.新增 相册功能，用于查看分享的图片文件夹','4.新增 相册自动分类功能，可根据人像，风景，地点，等区分','5.新增 文件在线编辑功能','6.新增 文件夹共享功能'],
				},{
					title: "2024-10-6 新功能",
					content: ['1.上线影视仓库功能，将上传的电影添加到影视仓库可自动解析电影信息。','2.修复重命名bug','3.修复清空回收站逻辑'],
				},{
					title: "2024-10-3 更新",
					content: ['1.文件管理功能适配手机端'],
				},{
					title: "2024-9-28 更新",
					content: ['1.修复 xml 文件无法上传问题'],
				},{
					title: "2024-8-25 更新",
					content: ['1.修复文件上传进度图标显示问题'],
				},{
					title: "2024-7-28 更新",
					content: ['1.修复文件上传进度显示错误问题'],
				},{
					title: "2024-6-22 更新",
					content: ['1.分享文件 增加分享有效期 1年和永久'],
				},{
					title: "2024-6-8 更新",
					content: ['1.修复CMYK的图片不显示缩略图的问题'],
				},{
					title: "2024-5-11 更新",
					content: ['1.将视频文件添加播放标识，便于识别文件类型'],
				},{
					title: "2024-4-13 更新",
					content: ['1.修复移动文件夹，文件未移动的问题'],
				},{
					title: "2024-3-16 更新",
					content: ['1.个人中心可修改头像姓名'],
				},{
					title: "2024-2-17 更新",
					content: ['1.将PDT和PPT读取第一页作为缩略图'],
				},{
					title: "2024-1-21 更新",
					content: ['1.将文件名显示为2行，便于快速查看详细名字'],
				},{
					title: "2023-12-17 更新",
					content: ['1.新增多种文件在线预览:','支持 doc, docx, xls, xlsx, xlsm, ppt, pptx, csv, tsv, dotm, xlt, xltm, dot, dotx,xlam, xla 等 Office 办公文档','支持 wps, dps, et, ett, wpt 等国产 WPS Office 办公文档','支持 odt, ods, ots, odp, otp, six, ott, fodt, fods 等OpenOffice、LibreOffice 办公文档','支持 vsd, vsdx 等 Visio 流程图文件','支持 psd 等 Photoshop 软件模型文件','支持 pdf ,ofd, rtf 等文档','支持 xmind 软件模型文件','支持 txt, xml(渲染), md(渲染), java, php, py, js, css 等所有纯文本','支持 zip, rar, jar, tar, gzip, 7z 等压缩包'],
				},{
					title: "2023-11-26 更新",
					content: ['1.优化office预览，提高30%访问速度'],
				},{
					title: "2023-10-15 更新",
					content: ['1.修改生成预览图逻辑，提高访问速度'],
				},{
					title: "2023-09-24 更新",
					content: ['1.修改重命名逻辑，2.修改截取视频预览图逻辑'],
				},{
					title: "2023-07-30 更新",
					content: ['1.选中视频文件后，属性中显示视频时长'],
				},{
					title: "2023-07-22 更新",
					content: ['1.修复 上传文件成功后不自动刷新问题'],
				},{
					title: "2023-05-20 更新",
					content: ['1.修复 部分分享文件弹窗异常'],
				},{
					title: "2023-03-12 更新",
					content: ['1.优化文件上传速度'],
				},{
					title: "2023-01-20 更新",
					content: ['1.优化 视频播放加载速度'],
				},{
					title: "2023-01-01 更新",
					content: ['1.新增 iso 图标','2.修改 打包下载逻辑','3.修复 大文件下载等待时间过长'],
				},{
					title: "2022-12-10 更新",
					content: ['1.新增 文件移动功能','2.新增 文件粘贴功能','3.新增 取消选中功能','4.修复 回收站文件错位问题','5.修复 文件夹打包下载'],
				},{
					title: "2022-10-01 更新",
					content: ['1.新增 office文件在线预览','2.新增 压缩包在线预览','3.新增 文本文件在线预览'],
				},{
					title: "2022-05-7 更新",
					content: ['1.新增 回收站','2.新增 文件收集','3.新增 多文件文件打包下载','4.新增 文件夹打包下载','5.修复 线上分享复制链接bug'],
				},{
					title: "2022-03-20  更新",
					content: ['1.新增 文件分享记录','2.新增 文件分享添加复制链接','3.修改 列表也预览图片尺寸','4.新增 选择文件；ctrl多选，点击单选','5.修改 上传列表页最小化的时候ui','6.修改 上传文件时，剩余时间问题处理'],
				},{
					title: "2022-03-06  更新",
					content: ['1.新增 文件上传，及其弹窗进度条','2.新增 文件下载算法','3.新增 上传文件夹','4.新增 重命名','5.新增 删除文件'],
				},
				]
			}
		}
	}
</script>

<style scoped>
	div {
		text-align: left;
		width: 80%;
		min-width: 600px;
		margin: 0 auto;
	}

	.title {
		padding: 20px;
		text-align: center;
	}

	.div-content-item {
		border: 3px solid #ecf5ff;
		margin-bottom: 20px;
		border-radius: 4px;
		
	}

	.div-content-item>h3 {
		background-color: #ecf5ff;
		padding: 10px;
	}

	.div-content-item>div {
		padding: 20px;
		margin:0;
	}
	

</style>
