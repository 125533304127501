<template>
	<div class="share-files-list">
		<div class="no-main-file-list nd-main-list__table">
			<div class="nd-table is-show-header">
				<template>
					<div class="box">
						<div class="tableBox">
							<div class="grid">
								<el-table :data="offlineFileList" style="width: 100%" height="calc('100% - 48px')">
									<el-table-column prop="id" label="文件id" min-width="100"  show-overflow-tooltip>
									</el-table-column>
									<el-table-column prop="fileName" label="文件名称" min-width="150" show-overflow-tooltip>
									</el-table-column>
									<el-table-column prop="progress" label="进度" min-width="100"  show-overflow-tooltip>
									</el-table-column>
									<el-table-column prop="speed" label="速度" min-width="100"  show-overflow-tooltip>
									</el-table-column>
									<el-table-column prop="parentPath" label="保存位置" min-width="240"
										show-overflow-tooltip>
										<template slot-scope="scope">
											{{ scope.row.parentPath + scope.row.fileName}}
										</template>
									</el-table-column>
									<el-table-column prop="status" label="状态" width="120">
										<template slot-scope="scope">
											{{ getStatusName(scope.row.status)}}
										</template>
									</el-table-column>
									<el-table-column prop="fileSize" label="大小" width="100">
										<template slot-scope="scope">
											{{ formatFileSize(scope.row.fileSize) }}
										</template>
									</el-table-column>
									<el-table-column prop="suffix" label="扩展名" width="100"></el-table-column>
									<el-table-column label="创建日期" width="240">
										<template
											slot-scope="scope">{{ getTimeString(scope.row.createTime) }}</template>
									</el-table-column>
									<el-table-column label="修改日期" width="240">
										<template
											slot-scope="scope">{{ getTimeString(scope.row.createTime) }}</template>
									</el-table-column>

								</el-table>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getOfflineFilesList
	} from "../../../api/file";
	import {
		Message
	} from "element-ui";
	import config from "../../../api/config";

	export default {
		name: "offlineDownload",
		data() {
			return {
				offlineFileList: [],
			}
		},
		mounted() {
			this.initGetShareFilesList();
		},
		methods: {
			initGetShareFilesList() {
				let vm = this;
				getOfflineFilesList({}, function(res) {
					if (res.code === 200 && res.success === true) {
						vm.offlineFileList = res.data.content;
					} else {
						Message.error(res.message);
					}
				})
			},

			// 时间转换
			getTimeString(utc) {
				return utc.replace('T', ' ').substr(0, 19);
			},
			// 状态判断
			getStatusName(status) {
				let str;
				switch (status) {
					case 0:
						str = "下载完成";
						break;
					case 1:
						str = "离线下载中";
						break;
					case 0:
						str = "下载失败";
						break;
					default:
						str = "状态异常";
						break;
				}
				return str;
			},
			// 文件大小格式化
			formatFileSize(fileSize) {
				if (fileSize <= 0) {
					return '0KB';
				}
				let k = 1024,
					units = ['B', 'KB', 'MB', 'GB', 'TB'],
					i = Math.floor(Math.log(fileSize) / Math.log(k));
				return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + units[i];
			},

		}
	}
</script>

<style>
	.share-files-list {
		height: 100%;
		/* padding: 18px 24px 0 18px; */
	}

	.nd-main-list__table {
		/* margin-left: -18px; */
	}

	.nd-main-list__table {
		height: calc(100% - 80px);
		/* width: calc(100% - 272px); */
		display: inline-block;
	}

	.nd-main-list__table {
		min-height: 356px;
	}


	.nd-table {
		height: 100%;
	}

	.box {
		width: 100%;
		height: 100%;
	}

	.tableBox {
		width: 100%;
		height: 100%;
		background: white;
	}

	.tableBox .grid {
		width: 100%;
		height: calc(100vh - 150px);
		overflow-y: scroll;
	}
</style>