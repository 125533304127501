import axios from 'axios'
import {
	Message
} from 'element-ui';
import router from "../router";
import config from "../api/config.js";
import md5 from 'md5';

// 全局上传队列和当前上传计数
let uploadQueue = [];
let currentUploads = 0;
const MAX_CONCURRENT_UPLOADS = 10;

//请求拦截器
axios.interceptors.request.use(config => {

	// if("undefined"===config.url){
	// 	console.log("config.url=undefined 拒绝请求")
	// 	return;
	// }
	// if(config.url.indexOf("/auth/getUserByToken/null")>-1){
	// 	console.log("config.url=/auth/getUserByToken/null 拒绝请求")
	// 	return;
	// }
	// console.log(config)

	//如果存在token，请求携带这个token
	if (window.sessionStorage.getItem('token')) {
		config.headers['Authorization'] = window.sessionStorage.getItem('token');
	}
	return config;
}, error => {
	console.log(error);
})

//响应拦截器
axios.interceptors.response.use(success => {
	// if (success.config.url === config.url.getUserByToken + window.sessionStorage.getItem('token')) {
	// 	return success
	// }

	//上传文件时候
	if (success.config.responseType === 'blob') {
		return success
	}

	//业务逻辑错误

	if (success.data.code != 200 && success.data.code != "200" && success.config.url.indexOf(
			"/sso/auth/getUserByToken/") == -1) {
		console.log(success.config.url + " 请求失败");
	}
	// console.log(success.config.url + " 请求成功");
	return success.data;


}, error => {
	if (error.response.status == 504 || error.response.status == 404) {
		Message.error({
			message: '服务器丢失，请联系管理员'
		})
	}
	if (error.response.status == 500 || error.response.status == 400) {
		Message.error({
			message: '请求失败请联系管理员'
		});
	} else if (error.response.status == 403) {
		Message.error({
			message: '权限不足请联系管理员'
		});
	} else if (error.response.status == 401) {
		Message.error({
			message: '登录超时,请重新登录!'
		});
		router.replace("/");
	} else {
		if (error.response.data.message) {
			Message.warning({
				message: error.response.data.message
			});
		} else {
			Message.error({
				message: '未知错误,请联系管理员'
			});
		}
	}
	return;
});

let base = '';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

//传送json格式的post请求
export const postRequest = (url, param, callback) => {
	param.companyCode = config.companyCode;
	return axios({
		method: 'post',
		url: `${base}${url}`,
		data: param,
		cancelToken: source.token,
		headers: {
			"Content-Type": "application/json"
		}
	}).then(response => {
		// 请求成功，调用回调函数
		// console.log(url+"POST 回调")
		callback(response);
	})
}

export const putRequest = (url, param, callback) => {
	return axios({
		method: 'put',
		url: `${base}${url}`,
		data: param
	}).then(response => {
		if (callback) {
			callback(response);
		}
	})
}

export const getRequest = (url, callback) => {
	return axios({
		method: 'get',
		url: `${base}${url}`,

		cancelToken: source.token
	}).then(response => {
		if (callback) {
			callback(response);
		}
	})
}

export const deleteRequest = (url, param) => {
	return axios({
		method: 'delete',
		url: `${base}${url}`,
		data: param
	})
}


export const getContentLong = (url, param) => {
	return axios({
		type: "head",
		async: false,
		url: url,
		dataType: "jsonp",
		jsonp: "callback", //传递给请求处理程序或页面的，用以获得jsonp回调函数名的参数名(一般默认为:callback)
		jsonpCallback: "?", //自定义的jsonp回调函数名称，默认为jQuery自动生成的随机函数名，也可以写"?"，jQuery会自动为你处理数据
		success: function(json) {
			alert(json);
		},
		error: function() {
			alert('fail');
		}
	});
}



// 处理上传任务
function handleUploadTask() {
	// 当前上传数小于最大并发数且队列非空时，执行上传
	while (currentUploads < MAX_CONCURRENT_UPLOADS && uploadQueue.length > 0) {
		const task = uploadQueue.shift();
		currentUploads++;
		task();
	}
}

// 添加上传任务到队列
export function enqueueUploadTask(task) {
	uploadQueue.push(task);
	handleUploadTask();
}

// 上传完成后的处理
function onUploadComplete() {
	currentUploads--;
	handleUploadTask();
}

// 上传文件的函数
export function singleUpload(file, path, index, updateProgressCallback, success, fail,setCancelToken) {
	let formData = new FormData();
	formData.append("file", file);
	formData.append("parentPath", path);
	formData.append("permission", "private");
	formData.append("lastModifyTime", file.lastModified); //文件属性
	formData.append("uploadType", file.uploadType); //是否有重复，0正常没有重复的可以不管，1、有重复发，全部覆盖， -1、有重复发，全部不覆盖

	// 创建取消令牌（CancelToken）
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();

	// 将取消函数传递出去，以便在需要时调用
	setCancelToken(source.cancel);

	const reader = new FileReader();

	reader.onload = (e) => {
		const fileContent = e.target.result;
		// const fileMD5 = md5(fileContent);
		// formData.append("md5", fileMD5);

		axios.post(config.url.file.singleUpload, formData, {
			onUploadProgress: (progressEvent) => {
				let completeVal = (progressEvent.loaded / progressEvent.total) * 100 || 0;
				// 更新进度的逻辑
				updateProgressCallback(index, completeVal);
			}
		}).then((res) => {
			// 处理上传成功的逻辑
			success(res);
			onUploadComplete();
		}).catch((thrown) => {
			// 处理上传失败的逻辑
			fail(thrown);
			onUploadComplete();
		});
	};
	reader.readAsBinaryString(file);
}



export default class HttpRequest {}