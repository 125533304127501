import config from './config'
import {
	postRequest,
	getRequest,
	putRequest,
	deleteRequest
} from "../utils/http-request";
import MessageBox from '@/components/message-box'
import result from "element-ui/packages/result";
import {
	Message
} from "element-ui";
import router from "../router";
import axios from "axios";

//添加到影视库
export function addToMovie(dataForm, callback) {
	postRequest(config.url.movie.add, dataForm, callback);
}

//更新影视
export function updateMovie(dataForm, callback) {
	postRequest(config.url.movie.update, dataForm, callback);
}

//查询影视
export function findListMovie(dataForm, callback) {
	postRequest(config.url.movie.findList, dataForm, callback);
}

//移出影视库
export function removeOfMovie(dataForm, callback) {
	postRequest(config.url.movie.remove, dataForm, callback);
}
