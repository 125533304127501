<template>
  <div class="share-files-list">
    <div class="no-main-file-list nd-main-list__table">
      <div class="nd-table is-show-header">
        <template>
          <div class="box">
            <div class="tableBox">
              <div class="grid">
                <el-table :data="shareFilesDataList" style="width: 100%" height="calc('100% - 48px')">

                  <el-table-column prop="name" label="文件名称" min-width="240"
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column label="分享链接" min-width="240" show-overflow-tooltip>
                    <template slot-scope="scope">{{ getShareLink(scope.row.id) }}</template>
                  </el-table-column>
                  <el-table-column label="分享日期" width="200">
                    <template slot-scope="scope">{{ getTimeString(scope.row.shareTime) }}</template>
                  </el-table-column>
                  <el-table-column label="过期时间" width="200">
                    <template slot-scope="scope">{{ getTimeString(scope.row.expirationTime) }}</template>
                  </el-table-column>
                  <el-table-column label="是否过期" width="80">
                    <template slot-scope="scope">{{ getIsBeOverdue(scope.row.expirationTime)}}</template>
                  </el-table-column>
                  <el-table-column prop="viewCount" label="浏览人数" width="80">
                  </el-table-column>
                  <el-table-column
                      fixed="right"
                      label="操作"
                      width="240">
                    <template slot-scope="scope">
                      <el-button @click="copyPassword(getShareLink(scope.row.id))" type="text" size="small">复制链接</el-button>
                      <el-button @click="copyPassword(scope.row.password)" type="text" size="small">复制提取码</el-button>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {getShareFilesList} from "../../../api/share";
import {Message} from "element-ui";
import config from "../../../api/config";

export default {
  name: "shareFiles",
  data() {
    return {
      shareFilesDataList: [],
    }
  },
  mounted() {
    this.initGetShareFilesList();
  },
  methods: {
    initGetShareFilesList() {
      let vm = this;
      getShareFilesList({}, function (res) {
        console.log(res)
        if (res.code === 200 && res.success === true) {
          vm.shareFilesDataList = res.data;
        } else {
          Message.error(res.message);
        }
      })
    },

    getShareLink(shareId) {
      return window.location.origin + '/cloud' + config.url.share.getShareFiles + shareId;
    },

    // 时间转换
    getTimeString(utc) {
      return utc.replace('T', ' ').substr(0, 19);
    },
    //是否过期
    getIsBeOverdue(expirationTime) {
      let today = new Date();
      if (today > expirationTime) {
        return '是';
      } else {
        return '否';
      }
    },
    copyPassword (text) {
      var copyTest = text;
      var inputTest = document.createElement('input');
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = 'oInput';
      inputTest.style.display = 'none';
      Message.success('复制成功！');
    }
  }
}
</script>

<style>
.share-files-list {
  height: 100%;
  padding: 18px 24px 0 18px;
}

.nd-main-list__table {
  margin-left: -18px;
}

.nd-main-list__table {
  height: calc(100% - 80px);
  width: calc(100% - 272px);
  display: inline-block;
}

.nd-main-list__table {
  min-height: 356px;
}


.nd-table {
  height: 100%;
}

.box {
  width: 100%;
  height: 100%;
}

.tableBox {
  width: 100%;
  height: 100%;
  background: white;
}

.tableBox .grid {
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: scroll;
}
</style>