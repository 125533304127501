<template>
	<div class="movie-query-page">
		<!-- 筛选区域 -->
		<div class="filter-section">
			<div class="filter-item" v-for="(options, category) in filters" :key="category">
				<span>{{ categoryLabels[category] }}:</span>
				<el-button-group>
					<el-button :type="selectedFilters[category] === '' ? 'primary' : 'default'"
						@click="updateFilter(category, '')">
						全部
					</el-button>
					<el-button v-for="option in options" :key="option"
						:type="selectedFilters[category] === option ? 'primary' : 'default'"
						@click="updateFilter(category, option)">
						{{ option }}
					</el-button>
				</el-button-group>
			</div>
		</div>

		<!-- 电影展示区域 -->
		<div class="movie-grid">
			<div v-if="filteredMovies.length === 0" class="no-movies">
				<svg t="1728479122056" class="icon" viewBox="0 0 1207 1024" version="1.1"
					xmlns="http://www.w3.org/2000/svg" p-id="5245" width="200" height="200">
					<path
						d="M1033.457099 320.65516H174.456258L0 633.537016v390.462984h1207.774096V633.524356L1033.444439 320.65516zM757.352851 637.005885a153.465803 153.465803 0 0 1-306.931606 0H72.023342l139.729588-266.292666h784.141635l139.729588 266.292666zM616.205331 0a26.32036 26.32036 0 0 1 26.32036 26.32036v136.475941a26.32036 26.32036 0 1 1-52.64072 0V26.32036A26.32036 26.32036 0 0 1 616.205331 0zM251.303101 75.289649a26.33302 26.33302 0 0 1 37.220711 0l96.609267 96.545967a26.296306 26.296306 0 0 1-37.208051 37.170071L251.303101 112.48504a26.28238 26.28238 0 0 1 0-37.195391z m579.832847 96.621928l96.621927-96.533307a26.3077 26.3077 0 1 1 37.208052 37.195391l-96.672569 96.520647a26.3077 26.3077 0 1 1-37.208051-37.195391z m0 0"
						fill="#D7D7D7" p-id="5246"></path>
					<path d="M211.75293 370.713219h784.242916v129.386282H211.75293z" fill="#D7D7D7" opacity=".3"
						p-id="5247"></path>
					<path
						d="M70.757331 769.936996l141.16018-269.837495v-129.386282L70.757331 640.538054v129.398942zM1137.168686 769.936996l-141.16018-269.837495v-129.386282l141.16018 269.824835v129.398942z"
						fill="#D7D7D7" opacity=".6" p-id="5248"></path>
				</svg>
				<p>暂无资源</p>
				<p>请前往【全部文件】中选中视频文件后添加</p>
			</div>
			<div v-else class="movie-item" v-for="movie in paginatedMovies" :key="movie.id" @click="openDialog(movie)">
				<el-popover trigger="hover" placement="top" delay="0" close-delay="0">
					<div slot="reference" class="movie-poster-container">
						<img :src="movie.poster" alt="Movie Poster" class="movie-poster" @error="handleImageError" />
						<div class="movie-rating">{{ movie.average }}</div>
					</div>
					<div class="movie-details">
						<p><strong>类型：</strong>{{ movie.type }}</p>
						<p><strong>年代：</strong>{{ movie.year }}</p>
						<p><strong>导演：</strong>{{ movie.director }}</p>
						<p><strong>演员：</strong>{{ movie.casts }}</p>
						<p><strong>评分：</strong>{{ movie.average }}</p>
					</div>
				</el-popover>
				<div class="movie-name">{{ movie.name }}</div>
				<div class="movie-year">{{ movie.year }}</div>
			</div>
		</div>

		<!-- 分页组件 -->
		<div class="pagination-section" v-if="filteredMovies.length > pageSize">
			<el-pagination background layout="prev, pager, next" :current-page.sync="currentPage" :page-size="pageSize"
				:total="filteredMovies.length" @current-change="handlePageChange">
			</el-pagination>
		</div>

		<!-- 视频播放弹框 -->
		<el-dialog :title="videoName" :visible.sync="dialogPlay" width="70%" @close="closeDialog" class="custom-dialog custom-vido-dialog">
			<div class="dialog-content">
				<video :src="videoUrl" ref="videoPlayer" autoplay class="video" width="100%"
					controls="controls"></video>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Button,
		ButtonGroup,
		Pagination,
		Popover,
		Dialog,
		Message,
		Icon
	} from 'element-ui';

	import config from "../../../api/config";
	import {
		findListMovie
	} from "../../../api/movie";

	export default {
		components: {
			ElButton: Button,
			ElButtonGroup: ButtonGroup,
			ElPagination: Pagination,
			ElPopover: Popover,
			ElDialog: Dialog,
			ElIcon: Icon,
		},
		data() {
			return {
				// 筛选选项
				filters: {
					type: [],
					year: [],
					// tag: [],
					// language: [],
					// category: [],
				},
				// 筛选类别的中文标签
				categoryLabels: {
					type: '类型',
					year: '年代',
					tag: '标签',
					language: '语言',
					category: '分类',
				},
				// 选中的筛选条件
				selectedFilters: {},
				// 电影数据
				movies: [],
				// 分页数据
				currentPage: 1,
				pageSize: 30, // 每页显示16个电影
				// 视频播放弹框
				dialogPlay: false,
				videoName: '',
				videoUrl: '',
				moviePoster: ''
			};
		},
		mounted() {
			// 初始化 selectedFilters 对象
			Object.keys(this.filters).forEach((key) => {
				this.$set(this.selectedFilters, key, '');
			});

			// 初始化电影数据
			this.initMovies();
		},
		methods: {
			updateFilter(category, value) {
				this.selectedFilters[category] = value;
				this.currentPage = 1; // 重置到第一页
			},
			handlePageChange(page) {
				this.currentPage = page;
			},
			initMovies() {
				// 查询电影信息
				findListMovie({
					status: 0,
					page: this.currentPage,
					size: this.pageSize,
					orders:[{
						property:'year',
						direction:'DESC'
					}],
				}, (res) => {
					if (res.code === 200 && res.success === true) {
						this.movies = res.data.content;
						this.initFilters(); // 初始化筛选选项
					} else {
						Message.error(res.message);
					}
				});
			},
			initFilters() {
				// 根据返回的电影数据动态生成筛选条件
				this.movies.forEach(movie => {
					if (movie.type) {
						movie.type.split(', ').forEach(type => {
							if (!this.filters.type.includes(type)) {
								this.filters.type.push(type);
							}
						});
					}
					if (movie.language && !this.filters.language.includes(movie.language)) {
						this.filters.language.push(movie.language);
					}
					// if (movie.category && !this.filters.category.includes(movie.category)) {
					// 	this.filters.category.push(movie.category);
					// }
					if (movie.year && !this.filters.year.includes(movie.year.toString())) {
						this.filters.year.push(movie.year.toString());
					}
					if (movie.tag && !this.filters.tag.includes(movie.tag)) {
						this.filters.tag.push(movie.tag);
					}
				});
				// 对年份进行倒序排列
				this.filters.year.sort((a, b) => b - a);
			},
			openDialog(movie) {
				this.videoName = movie.name;
				this.videoUrl = config.url.file.preview + movie.fileId; // 这里替换成真实的视频URL
				this.moviePoster = movie.poster;
				this.dialogPlay = true;
			},
			closeDialog() {
				this.dialogPlay = false;
				if (this.$refs.videoPlayer) {
					this.$refs.videoPlayer.pause(); // 暂停视频
				}
			},
			handleImageError(event) {
				event.target.src = 'https://via.placeholder.com/150x200'; // 占位图 URL
			}
		},
		computed: {
			// 根据筛选条件过滤电影
			filteredMovies() {
				return this.movies.filter((movie) => {
					return Object.keys(this.selectedFilters).every((key) => {
						return (
							this.selectedFilters[key] === '' ||
							movie[key]?.toString().includes(this.selectedFilters[key])
						);
					});
				});
			},
			// 当前页显示的电影
			paginatedMovies() {
				const start = (this.currentPage - 1) * this.pageSize;
				const end = start + this.pageSize;
				return this.filteredMovies.slice(start, end);
			},
		},
	};
</script>

<style>
	.movie-query-page {
		padding: 20px;
	}

	.filter-section {
		margin-bottom: 20px;
	}

	.filter-item {
		margin-bottom: 5px;
		display: flex;
		align-items: center;
	}

	.filter-item span {
		font-weight: bold;
		margin-right: 10px;
		white-space: nowrap;
	}

	.movie-grid {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}

	.movie-item {
		width: 180px;
		/* 固定宽度 */
		height: 310px;
		/* 固定高度 */
	}

	.movie-poster-container {
		position: relative;
	}

	.movie-poster {
		width: 100%;
		height: 266.66px;
		object-fit: cover;
		/* 确保图片充满容器，必要时裁剪 */
		object-position: center;
		/* 将图片内容保持在中心 */
		border-radius: 10px;
	}

	.movie-rating {
		position: absolute;
		top: 10px;
		left: 10px;
		background-color: rgba(0, 0, 0, 0.7);
		color: #fff;
		padding: 2px 5px;
		border-radius: 3px;
		font-size: 12px;
	}

	.movie-language {
		position: absolute;
		bottom: 10px;
		right: 10px;
		background-color: rgba(0, 0, 0, 0.7);
		color: #fff;
		padding: 2px 5px;
		border-radius: 3px;
		font-size: 12px;
	}

	.movie-name {
		text-align: center;
		margin-top: 5px;
	}

	.movie-year {
		font-size: 0.8rem;
		color: #888;
	}

	.pagination-section {
		margin-top: 20px;
		text-align: center;
	}

	.movie-details {
		padding: 10px;
		background-color: #000;
		color: #fff;
		border-radius: 10px;
		width: 300px;
	}

	.custom-dialog {
		.el-dialog__header {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			background-color: #000;
			color: #fff;
		}

		.el-dialog__body {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			background-color: #000;
			color: #fff;
		}
	}

	.dialog-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.dialog-movie-poster {
		width: 150px;
		border-radius: 10px;
	}

	.el-button {
		padding: 10px 18px;
	}

	.el-popover {
		border-radius: 10px !important;
		padding: 0 !important;
	}

	.custom-video-dialog>.el-dialog {
		background: black !important;
		border-radius: 10px;
	}

	.el-dialog__title {
		color: white !important;
	}

	.no-movies {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #888;
		width: 100%;
		height: 200px;
		gap: 10px;
	}

	.no-movies svg {
		fill: #bbb;
	}
</style>