import axios from "axios";
import config from "./config";
const instance = axios.create({
})
instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.post["Authorization"] = window.sessionStorage.getItem('token');
instance.defaults.withCredentials = false;// 携带cookie
//上传附带axios接口封装
const upload = {
    uploadFile(url, payload, cd) {
        return instance({
            url: url,
            method: "post",
            data: payload,
            onUploadProgress: function (progressEvent) {
                if (progressEvent.lengthComputable) {
                    cd(progressEvent);
                }
            }
        });
    },
}
export {
    upload,
    axios
}