<template>
  <div class="files-main-list">
    <div class="file-toolbar__wrapper">
      <div class="btn-group__wrapper" style="float: left">
        <el-button-group v-if="collectFilePath === '/'">
          <el-button size="small" icon="el-icon-folder-add" @click="centerDialogVisible = true">新建收集</el-button>
          <el-button size="small" icon="el-icon-delete" @click="deleteFolders">删除</el-button>
        </el-button-group>
      </div>
    </div>
    <div class="files-list-toolbar__wrapper">
      <div class="files-list-left__wrapper">
        <div>
          <span class="tittle" v-if="collectFilePath != '/'" @click="lastPage"
                style="cursor:pointer; color: #3a8ee6">返回上级 </span>
          <span class="tittle">{{ collectFilePath }}</span>
        </div>
      </div>
      <div class="files-list-right__wrapper">
        <span class="files-right-count" v-if="collectFilePath === '/'">已加载全部，共{{ fileDataList.length }}个</span>
        <span class="files-right-count" v-if="collectFilePath != '/'">已收集{{ fileDataList.length }}个</span>
      </div>
    </div>
    <div class="no-main-file-list nd-main-list__table">
      <div class="nd-table is-show-header">
        <template>
          <div class="box">
            <!-- 列表布局 -->
            <div class="tableBox">
              <div class="grid">
                <el-table :data="fileDataList" style="width: 100%" height="calc('100% - 48px')"
                          @selection-change="handleSelectionChange">
                  <el-table-column type="selection" width="50">
                  </el-table-column>

                  <el-table-column>
                    <template slot-scope="scope">
                      <img style="height: 23px; width: 23px;position: absolute;top:50%;transform: translateY(-50%);"
                           :src="getImgSrc(scope.row)" alt="这是图片" @dblclick="openDir(scope.row)">
                    </template>
                  </el-table-column>
                  <el-table-column prop="fileName" label="文件名称" min-width="240" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="fileSize" label="大小" width="140">
                    <template slot-scope="scope">
                      {{ formatFileSize(scope.row.fileSize) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="创建日期" width="240" v-if="collectFilePath === '/'">
                    <template slot-scope="scope">{{ getTimeString(scope.row.createTime) }}</template>
                  </el-table-column>
                  <el-table-column label="上传日期" width="240" v-if="collectFilePath != '/'">
                    <template slot-scope="scope">{{ getTimeString(scope.row.createTime) }}</template>
                  </el-table-column>
                  <el-table-column label="截止日期" width="240" v-if="collectFilePath === '/'">
                    <template slot-scope="scope">{{ getTimeString(scope.row.cutOffTime) }}</template>
                  </el-table-column>
                  <el-table-column label="状态" width="140" v-if="collectFilePath === '/'">
                    <template slot-scope="scope">{{ getStatusString(scope.row.cutOffTime) }}</template>
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="240"  v-if="collectFilePath === '/'">
                    <template slot-scope="scope">
                      <el-button @click="copyCollectLinkByText(getCollectLink(scope.row.fileId))" type="text" size="small">复制链接</el-button>
                      <el-button @click="openDir(scope.row)" type="text" size="small">查看</el-button>
                      <el-button @click="zipDownloadFolder(scope.row)" type="text" size="small">下载</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <el-dialog title="新建收集文件资料"
               :visible.sync="centerDialogVisible"
               width="450px"
               center>
      <el-form :rules="rules" ref="collectForm" :model="collectForm" class="collect-file">
        <el-form-item prop="folderName" label="收集标题" label-width="20%">
          <el-input v-model="collectForm.folderName"></el-input>
        </el-form-item>
        <el-form-item prop="cutOffTime" label="截止日期" label-width="20%">
          <el-date-picker
              v-model="collectForm.cutOffTime"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="description" label="详细描述" label-width="20%">
          <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="collectForm.description">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="collect-file_dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="collectFile">确定</el-button>
    </span>
    </el-dialog>

    <el-dialog title="新建收集文件资料"
               :visible.sync="collectFileDialogVisible"
               width="450px"
               center>
      <div class="wp-share-file">
        <span style="color: #3a8ee6"><i class="el-icon-circle-check"></i>成功创建收集文件资料</span>
      </div>
      <div class="wp-share-file">
        <el-input
            placeholder="收集链接"
            v-model="collectLink"
            :disabled="true">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyCollectLink">复制链接</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";
import {createCollect, getCollectFileList, getCollectFolderList} from "../../../api/colllectFile";
import config from "../../../api/config";
import {postRequest} from "../../../utils/http-request";
import Utils from "../../../assets/util";
import axios from "axios";

export default {
  name: "collectFiles",
  data() {
    return {
      //当前路径
      collectFilePath: '/',//当前节点
      fileDataList: [], //文件列表
      centerDialogVisible: false,
      collectFileDialogVisible: false,//创建成功后弹窗
      collectForm: {
        path: '/',
        folderName: '',
        cutOffTime: '',
        description: ''
      },
      rules: {
        folderName: [{required: true, message: '请输入收集标题', trigger: 'blur'}],
        cutOffTime: [{required: true, message: '请选择日期', trigger: 'blur'}],
      },
      multipleSelection: [], //选中文件
      selectedState: [],//选中文件id
      collectLink: ''//收集文件链接
    }
  },
  mounted() {
    this.initGetFolderList();
  },
  methods: {
    async initGetFolderList() {
      const vm = this;
      await getCollectFolderList({}, function (res) {
        if (res.code === 200 && res.success === true) {
          vm.fileDataList = res.data;
        } else {
          Message.error(res.message);
        }
      })
    },
    collectFile() {
      this.$refs.collectForm.validate((valid) => {
        const vm = this;
        if (valid) {
          createCollect(this.collectForm, function (res) {
            if (res.code === 200 && res.data.count === 0) {
              Message.success('创建成功！');
              vm.centerDialogVisible = false;
              vm.initGetFolderList();
              vm.collectLink = window.location.origin + '/cloud' + config.url.collectFiles + res.data.collectId;
              vm.collectFileDialogVisible = true;
            } else if (res.code === 200 && res.data.count > 0) {
              Message.error(res.message);
            } else {
              Message.error('创建失败，请联系管理人员！');
            }
          })
        } else {
          this.$message.error('请输入正确信息');
          return false;
        }
      })
    },

    //删除
    deleteFolders () {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择文件！');
      } else {
        let dataForm = {"fileIds": this.selectedState};
        this.$confirm('此操作将文件移动到回收站, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          postRequest(config.url.deleteCollectFolder, dataForm).then(res => {
            if (res.code === 200 && res.success === true) {
              Message.success(res.message);
              // location.reload();
              this.initGetFolderList();
              Utils.$emit('getMemory');
              //清空选中文件
              this.selectStatus = [];
              this.multipleSelection = [];
            } else {
              Message.error(res.message);
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },

    //打开文件夹
    openDir(item) {
      const vm = this;
      vm.collectFilePath = vm.collectFilePath + item.fileName + "/";
      //获取收集文件列表
      getCollectFileList({path: vm.collectFilePath, parentFileId: item.fileId}, function (res) {
        if (res.code === 200 && res.success === true) {
          vm.fileDataList = res.data;
        } else {
          Message.error(res.message);
        }
      })
    },

    //返回上一级
    lastPage() {
      //清空选中文件
      this.multipleSelection = [];
      this.selectedState = [];
      this.collectFilePath = '/';
      this.initGetFolderList();
    },

    getCollectLink(collectId) {
      return window.location.origin + '/cloud' + config.url.collectFiles  + collectId;
    },

    //复制收集链接
    copyCollectLink() {
      const copyTest = this.collectLink;
      const inputTest = document.createElement('input');
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = 'oInput';
      inputTest.style.display = 'none';
      Message.success('复制成功！');
    },

    //复制收集链接
    copyCollectLinkByText(text) {
      var copyTest = text;
      var inputTest = document.createElement('input');
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = 'oInput';
      inputTest.style.display = 'none';
      Message.success('复制成功！');
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectedState = [];
      for (let i = 0; i < val.length; i++) {
        let str = val[i].fileId
        this.selectedState.push(str)
      }
    },
    // 文件图标获取
    getImgSrc(item) {
      let isDir = item.isDir,
          suffix = item.suffix,
          category = item.category,
          fileId = item.fileId;
      let icon = 'other';
      if (category === 3 || category === 1) {
        icon = config.url.getImgUrl + fileId;
        return icon;
      } else {
        if (isDir == 1) {
          icon = "folder";
        } else {
          switch (category) {
              case 1:
                icon = "video";
                break;
            case 2:
              icon = "music";
              break;
            case 4: //文本
              icon = suffix;
              if (icon == "doc" || icon == "docx") {
                icon = "doc";
              } else if (icon == "xls" || icon == "xlsx" || icon == "csv") {
                icon = "xls";
              }
              break;
            case 5: //应用
            case 6: //压缩包
              icon = suffix;
              break;
            case 7:
              icon = "torrent";
              break;
            case 8:
              icon = "code";
              break;
            case 9:
            default:
              icon = "other";
              break;
          }
        }
       return `/cloud/image/file_type/${icon}.png`;
      }

    },

    // 时间转换
    getTimeString(utc) {
      return utc.replace('T', ' ').substr(0, 19);
    },
    //判断当前状态，已截止或进行中
    getStatusString(utc) {
      let nowDate = new Date();
      let nowTime = new Date(nowDate).getTime()// 当前时间精确到毫秒
      if (nowTime > new Date(utc).getTime()) {
        return '已截止';
      } else {
        return '进行中';
      }
    },

    // 文件大小格式化
    formatFileSize(fileSize) {
    	if (fileSize <= 0) {
    		return '0KB';
    	}
    	let k = 1024,
    		units = ['B', 'KB', 'MB', 'GB', 'TB'],
    		i = Math.floor(Math.log(fileSize) / Math.log(k));
    	return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + units[i];
    },
    //收集文件下载
    zipDownloadFolder(item) {
      axios({
        url: `${config.url.downloadCollectFolder}${item.fileId}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        let blob = new Blob([response.data],{type: 'application/zip'})
        let url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = item.fileName
        link.click()
        URL.revokeObjectURL(url)
      });
    }
  }
}
</script>

<style>

.files-main-list {
  height: 100%;
  padding: 18px 24px 0 18px;
}

.btn-group__wrapper {
  display: inline-block;
  vertical-align: middle;
}

.files-list-toolbar__wrapper {
  border-radius: 4px 4px 0 0;
  height: 40px;
  line-height: 40px;
  border-bottom-width: 0;
  overflow: hidden;
  padding: 6px 14px 0 12px;
  width: calc(100% - 20px);
}

.files-list-left__wrapper {
  float: left;
}

.files-list-left__wrapper .tittle {
  font-size: 1rem;
  font-size: 1rem;
  color: #03081a;
  font-weight: 700;
}

.files-list-right__wrapper {
  float: right;
  color: #afb3bf;
  font-size: 1rem;
}

.dialog-body {
  font-family: "Microsoft YaHei", SimSun;
  background-color: white;
  font-size: 12px;
  color: #424e67;
  overflow: scroll;
  height: 255px;
}

.collect-file {
  margin-top: 8px;
}

.collect-file_dialog-footer {
  text-align: center;
  margin-top: 24px;
  font-weight: 700;
}

.nd-main-list__table {
  margin-left: -18px;
}

.nd-main-list__grid,
.nd-main-list__table {
  height: calc(100% - 80px);
  width: calc(100% - 272px);
  display: inline-block;
}

.nd-main-list__detail,
.nd-main-list__grid,
.nd-main-list__table {
  min-height: 356px;
}

.nd-table {
  height: 100%;
}

.nd-main-list__detail,
.nd-main-list__grid,
.nd-main-list__table {
  min-height: 356px;
}

.nd-table {
  height: 100%;
}

.box {
  width: 100%;
  height: 100%;
}

.tableBox {
  width: 100%;
  height: 100%;
  background: white;
}

.tableBox .grid {
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: scroll;
}
</style>