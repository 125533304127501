<template>
  <div>
    <div class="no-gutters board-auth-box">
      <div class style="max-width: 450px; width: 90%; margin: auto;">
        <el-form :rules="rules" ref="changePwdForm" :model="changePwdForm" class="changePwdContainer" label-width="25%">
          <h3 class="changePwdTitle">修改密码</h3>
          <el-form-item prop="oldPwd" label="旧密码">
            <el-input type="password" auto-complate="false" v-model="changePwdForm.oldPwd"
                      placeholder="请输入旧密码"></el-input>
          </el-form-item>
          <el-form-item prop="newPwd" label="新密码">
            <el-input type="password" auto-complate="false" v-model="changePwdForm.newPwd"
                      placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item prop="confirmPwd" label="确 认">
            <el-input type="password" auto-complate="false" v-model="changePwdForm.confirmPwd"
                      placeholder="请输入确认密码"></el-input>
          </el-form-item>

          <el-button type="primary" @click="changePassword">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {changePwd} from "../../api/auth";
import {Message} from "element-ui";
import router from "../../router";

export default {
  name: "ChangePassword",
  data() {
    return {
      changePwdForm: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
      },
      rules: {
        oldPwd: [{required: true, message: '请输入旧密码', trigger: 'blur'}],
        newPwd: [{required: true, message: '请输入新密码', trigger: 'blur'}],
        confirmPwd: [{required: true, message: '请输入确认密码', trigger: 'blur'}],
      }
    }
  },
  methods: {
    changePassword() {
      if(this.changePwdForm.newPwd === this.changePwdForm.oldPwd) {
        this.$message.error("新密码与旧密码一样，请重新输入！")
      } else if (this.changePwdForm.newPwd != this.changePwdForm.confirmPwd) {
        this.$message.error("新密码与确认密码不一致，请重新输入！")
      } else {
        this.$refs.changePwdForm.validate((valid) => {
          if (valid) {
            delete this.changePwdForm.confirmPwd;
            changePwd(this.changePwdForm, function (res) {
              if (res.code === 200 && res.success === true) {
                Message.success('修改成功！');
                //清空token
                window.sessionStorage.clear();
                //跳转到登陆页
                router.replace('/');
              } else {
                Message.error(res.message);
              }
            })
          } else {
            this.$message.error('请输入所有字段');
            return false;
          }
        });
      }

    },
    cancel() {
      router.replace("/home");
    }
  }
}
</script>

<style>
.changePwdContainer {
  border-radius: 15px;
  padding: 15px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.changePwdTitle {
  margin: 0px auto 25px auto;
  text-align: center;
}
</style>